import React from "react";
import { useNavigate } from "react-router-dom";
import share from "../assets/share.mp4";
import logo from "../assets/walle.png";
import GoogleLogin from "react-google-login";
import { FcGoogle } from "react-icons/fc";
import { client } from "../client";
const Login = () => {
  const navigate = useNavigate();

  // console.log(process.env)
  const responseGoogle = (response) => {
    console.log(response);
    localStorage.setItem("user", JSON.stringify(response.profileObj));
    const { name, googleId, imageUrl } = response.profileObj;
    const doc = {
      _id: googleId,
      _type: "user",
      userName: name,
      image: imageUrl,
    };
    client.createIfNotExists(doc).then(() => {
      navigate("/", { replace: true });
    });
  };

  return (
    <div className="flex justify-start item-center flex-col h-screen">
      <div className="relative w-full h-full">
        <video
          src={share}
          type="video/mp4"
          loop
          controls={false}
          muted
          autoPlay
          className="w-full h-full object-cover"
        />
      </div>
      <div className="absolute flex flex-col justify-center items-center top-0 right-0 left-0 bottom-0 bg-overlay">
        <div className="p-5">
          <img src={logo} width="130px" alt="logo" />
        </div>
        <h2 className="text-1xl md:text-5xl text-white text-shadow-md opacity-0.7 pb-2">Welcome to Walle</h2>
        <div className="shadow-2xl">
          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_TOKEN}
            render={(renderProps) => (
              <button
                type="button"
                className="bg-mainColor flex justify-center items-center p-3 rounded-lg cursor-pointer outline-none"
                onClick={renderProps.onClick}
              >
                <FcGoogle className="mr-1" />
                Sign in with Google
              </button>
            )}
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            // cookiePolicy={"single_host_policy"}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
