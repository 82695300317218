import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navbar, Feed, PinDetails, CreatePin, Search, Category } from '../components/index';

const Pins = ({ user }) => {
  const [searchTerm, setSearchTerm] = useState('');
  return (
    <div className='px-2 md:px-5'>
      <div className='bg-gray-50'>
        <Navbar
          user={user}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      </div>
      <div className='h-full'>
        <Routes>
          <Route path='/' element={<Feed />} />
          <Route path='/category/:categoryId' element={<Category />} />
          <Route path='/pin-details/:pinId' element={<PinDetails user={user && user} />} />
          <Route path='/create-pin' element={<CreatePin user={user && user} />} />
          <Route path='/search' element={<Search searchTerm={searchTerm} setSearchTerm={setSearchTerm} />} />
          {/* <Route path='/' element={<Feed />} /> */}
          {/* <Route path='/' element={<Feed />} /> */}
        </Routes>

      </div>

    </div>
  )
}

export default Pins