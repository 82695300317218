import React from "react";
import { NavLink, Link } from "react-router-dom";
import { RiHomeFill } from "react-icons/ri";
import { categories } from '../utils/data';
// import { IoIosArrrowForward } from "react-icons/io";
import { AiFillCloseCircle } from "react-icons/ai";
import logo from "../assets/logo.png";

const Sidebar = ({ user, closeToggle }) => {
  const isNotActiveStyle =
    "flex items-center px-5 gap-3 my-3 text-gray-500 hover:text transition-all duration-200 ease-in-out capitalize";
  const isActiveStyle =
    "flex items-center px-5 gap-3 my-3 font-extrabold  border-r-2 border-black transition-all duration-200 ease-in-out capitalize";

  const handleCloseSidebar = () => {
    if (closeToggle !== undefined) {
      closeToggle(false);
    }
  };

  return (
    <div className="flex flex-col justify-between bg-white h-full overflow-y-scroll min-w-210 hide-scrollbar">
      <div className="flex flex-col">
        <div className="flex px-5 gap-2 my-5 w-150  items-center content-between">
          <Link to="/" className="" onClick={handleCloseSidebar}>
            <img src={logo} alt="logo" className=" top-0 text-center" width='50%' height='5%'/>
          </Link>
          <AiFillCloseCircle
            fontSize={30}
            className="cursor-pointer md:hidden"
            onClick={handleCloseSidebar}
          />
        </div>
        <NavLink
          to="/"
          className={({ isActive }) =>
            isActive ? isActiveStyle : isNotActiveStyle
          }
        >
          <RiHomeFill />
          Home
        </NavLink>
        <h3 className="mt-2 px-5 text-base 2xl:text-xl">Discover Categories</h3>
        {categories.slice(0, categories.length - 1).sort().map(({ name,image }) => (
          <NavLink
            key={name}
            to={`/category/${name}`}
            className={({ isActive }) =>
              isActive ? isActiveStyle : isNotActiveStyle
            }
            onClick={handleCloseSidebar}
          >
            <img
              src={image}
              className="w-8 h-8 rounded-full shadow-sm"
              alt="user-img"
            />
            {name}
          </NavLink>
        ))}
      </div>
      {user && (
        <Link
          to={`user-profile/${user._id}`}
          className="p-2 flex my-5 mb-3 gap-2 items-center bg-white rounded-lg shadow-lg mx-3"
        >
          <img
            src={user.image}
            className="w-10 h-10 rounded-full"
            alt="user-profile"
          />
          <p>{user.userName}</p>
        </Link>
      )}
    </div>
  );
};

export default Sidebar;
