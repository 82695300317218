import React, { useState, useRef, useEffect } from 'react'
import { HiMenu } from 'react-icons/hi';
// import { AiFillCloseCircle } from 'react-icons/ai';
import { Link, Route, Routes } from 'react-router-dom';
import { Sidebar, UserProfile } from '../components';
import { client } from '../client';
import logo from '../assets/logo.png';
import Pins from './Pins';
import { userQuery, fetchUser } from '../utils/data.js';


const Home = () => {
    const [toggleSidebar, setToggleSidebar] = useState(true)
    const [userInfo, setUser] = useState(null)
    const scrollRef = useRef(null)


    const user = fetchUser()
    useEffect(() => {
        const query = userQuery(user?.googleId);
        const params = {}
        client.fetch(query, params).then(
            (data) => {
                setUser(data[0]);
                console.log(data);
            })
        // eslint-disable-next-line 
    }, [])

    useEffect(() => {
        scrollRef.current.scrollTo(0, 0);
    })

    return (
        <div className='flex bg-gray-50 md:flex-row flex-col transition-height duration-75 ease-out'>
            <div className='hidden md:flex h-screen flex-initial'

                style={{
                    width: '270px'
                }}>
                <Sidebar user={userInfo && userInfo} closeToggle={setToggleSidebar} />
            </div>
            <div className='flex md:hidden flex-row  '>
                <div className='p-2 w-full flex flex-row items-center justify-between shadow-md'>
                    <HiMenu fontSize={40} className='cursor-pointer' onClick={() => {
                        setToggleSidebar(true)
                    }} />
                    <Link to='/'>
                        <img src={logo} alt='logo' className='w-28' />
                    </Link>
                    <Link to={`/user-profile/${user?._id}`}>
                        <img src={userInfo?.image} alt='logo' className='w-10 rounded-full' />
                    </Link>
                </div>
                {toggleSidebar &&
                    (
                        <div className='fixed w-4/5  bg-white h-screen overflow-y-auto shadow-md z-10 animate-slide-in'>
                            <div className='absolute w-full '>
                                <Sidebar user={userInfo && userInfo} closeToggle={setToggleSidebar} />
                            </div>

                        </div>
                    )
                }
            </div>
            <div className='pb-2 flex-1 h-screen overflow-y-scroll ' ref={scrollRef}>
                <Routes>
                    <Route path='/user-profile/:userId' element={<UserProfile />} />
                    <Route path='*' element={<Pins user={userInfo && userInfo} />} />
                </Routes>
            </div>

        </div>
    )
}

export default Home