import React, { useState } from "react";
import {  useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { MdDownloadForOffline } from "react-icons/md";
import { AiTwotoneDelete } from "react-icons/ai";
// import { BsFillArrowUpCircleFill } from "react-icons/bs";
import { client, urlFor } from "../client";
import { fetchUser } from "../utils/data.js";

const Pin = ({ pin: { postedBy, image, _id, destination, save } }) => {
  const user = fetchUser();
  const [postHovered, setPostHovered] = useState(false);
  console.log(postedBy._id=== user.googleId)

  const alreadySaved = !!save?.filter(
    (item) => item.postedBy._id === user.googleId
  )?.length;

  const savePin = (id) => {
    console.log(alreadySaved);
    console.log(save);
    console.log(id);
    if (!alreadySaved) {
      client
        .patch(id)
        .setIfMissing({ save: [] })
        .insert("after", "save[-1]", [
          {
            _key: uuidv4(),
            userId: user.googleId,
            postedBy: {
              _type: "postedBy",
              _ref: user.googleId,
            },
          },
        ])
        .commit()
        .then(() => {
          window.location.reload();
        });
    }
  };

  const deletePost = (id) => {
    // console.log(id);
    client.delete(id).then(() => {
      window.location.reload();
    });
  };
  const navigate = useNavigate();

  return (
    <div className="m-2">
      <div
        onMouseEnter={() => setPostHovered(true)}
        onMouseLeave={() => setPostHovered(false)}
        onClick={() => navigate(`/pin-details/${_id}`)}
        className="relative cursor-zoom-in w-auto hover:shadow-lg rounded-lg overflow-hidden transition-all duration-500 ease-in-out"
      >
        <img
          src={urlFor(image).width(250).url()}
          className="rounded-lg w-full"
          alt="user-post"
        />
        {postHovered && (
          <div
            className="absolute top-0 w-full h-full flex flex-col justify-between p-1 pr-2 pt-2"
            style={{ height: "100%" }}
          >
            <div className="flex items-center justify-between">
              <div className="flex gap-2 pt-2">
                <a
                  href={`${image?.asset?.url}?id=`}
                  download
                  onClick={(e) => e.stopPropagation()}
                  className="bg-white p-2 w-9 h-9 rounded-full flex items-center justify-center text-dark text-xl opacity-75 hover:opacity-100 hover:shadow-md outline-none"
                >
                  <MdDownloadForOffline />
                </a>
              </div>
              <div>
                {alreadySaved ? (
                  <button
                    type="button"
                    className="bg-red-500 bg-red-500 opacity-70 hover:opacity-100 text-white font-bold px-5 py-1 text-white rounded-3xl hover:shadow-md outlined-none mb-0"
                  >
                    {save?.length} saved
                  </button>
                ) : (
                  <button
                    className="bg-red-500 opacity-70 hover:opacity-100 text-white font-bold px-5 py-1 text-base rounded-3xl hover:shadow-md outlined-none mb-0"
                    onClick={(e) => {
                      e.stopPropagation();
                      savePin(_id);
                    }}
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
            <div className="flex ustify-between  items-center gap-2 w-full">
              {/* {destination && (
                <div className="flex justify-between  items-center gap-2 w-full ">
                  <a
                    href={destination}
                    target="_blank"
                    rel="noreferrer"
                    className="bg-white flex items-center gap-1 text-black font-bold p-2 pl-4 pr-4 rounded-full opacity:70 hover:opacity-100 hover:shadow-md"
                  >
                    <BsFillArrowUpCircleFill />
                    {destination.length > 20
                      ? destination.slice(8, 10)
                      : destination.slice(8)}
                  </a>
                </div>
              )} */}
              {postedBy._id === user.googleId && (
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    deletePost(_id);
                  }}
                  className="bg-red-500 opacity-70 hover:opacity-100 text-white font-bold px-5 py-2 text-base rounded-3xl hover:shadow-md outlined-none mb-0"
                >
                  <AiTwotoneDelete/>
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Pin;
